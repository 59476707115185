import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './elements/Home';
import LoginFailed from './elements/LoginFailed';
import NotFound from './elements/NotFound';
import CaseDetails from './elements/CaseDetails';
import Login from "./modules/login";
import { getParam } from './modules/param';

function App() {
  // const navigate = useNavigate()
  const [user, setUser] = useState(0)
  const [authToken, setAuthToken] = useState(0)

  useEffect(() => {
      setUser(JSON.parse(window.localStorage.getItem('user')));
      setAuthToken(JSON.parse(window.localStorage.getItem('authToken')));  
  }, []);

  useEffect(() => {
    if (user !== 0) {
      window.localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    if (user !== 0) {
      window.localStorage.setItem('authToken', JSON.stringify(authToken));
    }
  }, [authToken]);

  // const []

  // console.log(user)

  if (typeof authToken !== 'undefined' && authToken !== 0 && authToken !== null) {
    return (
      <Routes>
        <Route exact path="/" element={<Home user={user} auth={authToken} />} />
        <Route path="/case/*" element={<CaseDetails auth={authToken} />} />
        <Route exact path="/login-failed" element={<LoginFailed />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    )
  }

  return (
    <div>
      <Routes>
        <Route exact path='/' element={<Login user={setUser} auth={setAuthToken} />} />
        <Route exact path="/login-failed" element={<LoginFailed />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
