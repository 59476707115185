// import React, { useState } from "react";

import MessageBox from "./MessageBox";

export default function LoginFailed (props) {
    // super(props)
    return (
        <MessageBox>
            <h1>Error - login failed</h1>
            Please contact support
        </MessageBox>
    )    
}