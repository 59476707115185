const params = window.location.search.substr(1).split('&')

const getParam = (param) => {
    let returnItem = false

    params.forEach(element => {
        const item = element.split('=')
        if(item[0] === param) {
            returnItem = item[1]
        }
    });

    return returnItem
}

export { getParam }