import { makeRequest } from "../modules/xhr";
import CaseDetailsFunc from "../modules/caseDetails";
import { useState } from "react";
import { Link } from "react-router-dom";
import RenderFormElement from "./RenderFormElement";

export default function CaseDetails(props) {
    const [caseDetails, setCaseDetails] = useState()

    const caseid = window.location.pathname.split('/')
    CaseDetailsFunc(caseid[caseid.length - 1], props.auth, setCaseDetails)

    if (typeof caseDetails == 'undefined') {
        return (
            <div>
                <Link to='/'>Home</Link>
                <h1>Loading...</h1>
            </div>
        )
    }

    const details = caseDetails.item.data

    const fieldData = caseDetails.fields.data

    console.warn(details)

    const getFieldDetails = (lookupField) => {
        for (let field in fieldData.fields) {
            // console.warn(lookupField)
            if (fieldData.fields[field].name == lookupField) {
                return fieldData.fields[field]
            }
        }
    }

    // console.warn(details.fields)

    const Fields = () => {
        let response = []
        for (let field in details.fields) {
            // console.warn(field)
            const fieldDetails = getFieldDetails(field)
            
            if(typeof fieldDetails == 'undefined') {
                continue;
            }

            response.push(
                <RenderFormElement value={details.fields[fieldDetails.name]} name={fieldDetails.name} type={fieldDetails.field_type} />
            )
        }

        return (
            response
        )
    }

    return (
        <div>
            <Link to='/'>Home</Link>
            <h2>Case {details.label}</h2>
            <h3>Assigned to {details.relationships.assigned_to.label}</h3>
            <span>Created {new Date(details.created_at).toUTCString()}</span><br />
            <span>Last Updated {new Date(details.updated_at).toUTCString()}</span>

            <form>
                <fieldset>
                    <legend>Case details</legend>
                    <Fields />
                </fieldset>
            </form>
        </div>
    )
}