import React, { useState } from "react";
import { Link } from "react-router-dom";
import CasesList from "../modules/casesList.js";
import Cases from "./Cases.js";

export default function Home(props) {
    const [ caseList, setCaseList ] = useState()

    if(typeof props.user === 'undefined') {
        return <h1>Loading ...</h1>
    }

    const user = props.user
    // console.log(props.user)

    return (
        <div>
            <CasesList auth={props.auth} response={setCaseList} />
            <h3>Welcome, {user.label}</h3>
            <Cases caseList={caseList} />
            <div>
                <Link to='/case/1977298764'>HL1222</Link>
            </div>
        </div>
    )
}