import React, { useState } from "react";
import { Link } from "react-router-dom";
import CasesList from "../modules/casesList.js"

export default function Cases(props) {
    if (typeof props.caseList === 'undefined') {
        return
    }

    // console.warn(props.caseList)
    // const [caseList, setCaseList] = useState()

    // const cases = CasesList(props.auth, setCaseList())

    const caseList = props.caseList.results.filter((item, i) => {
        if (i > 10) return false

        return true
    })

    // const caseOutput = caseList.forEach(element => {
    //     return element.label
    // })

    // console.warn(caseList)

    return (
        <div>
            You have {props.caseList.results.length} Cases<br /><br />
            {caseList.map((item) => {
                return <div><Link to={'/case/'+item.id}>{item.label}</Link></div>
            })}
        </div>
    )
}