import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { makeRequest } from "./xhr.js"
import { getParam } from "./param.js"
import { BASE_URL } from "./globals.js"
import MessageBox from "../elements/MessageBox.js"

export default function Login(props) {
    const [val, setVal] = useState()
    const [success, setSuccess] = useState()

    const navigate = useNavigate()

    const doLogin = async (email = getParam('email'), password = getParam('password')) => {
        // var BASE_URL = 'http://localhost:3003'
        // var BASE_URL = 'https://api.qcontact.com'

        const user = await makeRequest(BASE_URL + '/api/v2/auth/sign_in?email=' + email + '&password=' + password, '', {}, 'POST')

        // console.log(user.headers)
        if (typeof user.data.success !== 'undefined' && user.data.success === false) {
            setSuccess(false)
            setVal(user.data.errors[0])

            navigate('/login-failed')
        } else {
            setSuccess(true)
            setVal(user.data.data.label)
            props.user(user.data.data)
            props.auth({
                'uid': decodeURIComponent(email),
                'client': user.headers.filter(h => h[0] == 'client')[0][1],
                'accessToken': user.headers.filter(h => h[0] == 'access-token')[0][1],
            })
        }
    }

    useEffect(() => {
        if (typeof getParam('email') == 'string') {
            doLogin()
        }
    }, [])

    if (typeof getParam('email') == 'boolean') {
        return (
            <div>
                <MessageBox>
                    <form method="get" action="/">
                        <fieldset>
                            <legend>Log in</legend>
                            <input type='email' name='email' placeholder="Email" required /><br />
                            <input type='password' name='password' placeholder="Password" required /><br />
                            <input type='submit' value="Log in" />
                        </fieldset>
                    </form>
                </MessageBox>
            </div>
        )
    }

    return true
}