import { useEffect } from "react";
import { makeRequest } from "./xhr";
import { BASE_URL } from './globals.js'

export default function CaseDetailsFunc(caseid, auth, response) {

    const getCase = async () => {
        const item = await makeRequest(BASE_URL + '/api/v2/entities/Case/' + caseid, null, {
            'access-token': auth.accessToken,
            'client': auth.client,
            'uid': auth.uid
        })

        const fields = await makeRequest(BASE_URL + '/api/v2/entity_types/Case', null, {
            'access-token': auth.accessToken,
            'client': auth.client,
            'uid': auth.uid
        })

        response({
            'item': await item,
            'fields': await fields
        })
    }

    useEffect(() => {
        getCase()
    }, [])
}