import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { makeRequest } from "./xhr"
import { BASE_URL } from "./globals"

export default function CasesList (props) {
    // const [val, setVal] = useState()
    const [success, setSuccess] = useState()

    const navigate = useNavigate()

    const getCases = async () => {
        // var BASE_URL = 'http://localhost:3003'
        // var BASE_URL = 'https://api.qcontact.com'

        const cases = await makeRequest(BASE_URL + '/api/v2/entities/Case?view=personal-forms', null, {
            'access-token': props.auth.accessToken,
            'client': props.auth.client,
            'uid': props.auth.uid
        })
        if (typeof cases.success !== 'undefined' && cases.success === false) {
            setSuccess(false)
            // setVal(cases.errors[0])

            navigate('/error')
        } else {
            setSuccess(true)
            // setVal(cases.data.label)
            // props.user(cases.data)
            props.response(cases.data)
        }
    }

    useEffect(() => {
        getCases()
    }, [])

    return true
}