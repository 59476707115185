export default function RenderFormElement(props) {
    // Field types at https://www.qcontact.com/documentation/business/api/#entityfieldtype

    switch (props.type) {
        case 0:
            // String
            return (
                <span>
                    <label>{props.name}</label> <input type='text' name={props.name} defaultValue={props.value} /> <br /><br />
                </span>
            )
            break;

        case 1:
            // Number
            return (
                <span>
                    <label>{props.name}</label> <input type='number' name={props.name} defaultValue={props.value} /> <br /> <br />
                </span>
            )
            break;

        case 2:
            // Bool
            return (
                <span>
                    <label>{props.name}</label> <input type='checkbox' name={props.name} defaultChecked={props.value} /> <br /> <br />
                </span>
            )
            break;

        case 3:
            // Lookup
            return (
                <span>
                    <label>{props.name}</label> <b>DROPDOWN FIELD</b> <br /> <br />
                </span>
            )
            break;

        case 4:
            // Password
            return (
                <span>
                    <label>{props.name}</label> <input type='password' name={props.name} /> <br /> <br />
                </span>
            )
            break;

        case 5:
            // Datetime
            return (
                <span>
                    <label>{props.name}</label> <input type='datetime-local' name={props.name} defaultValue={props.value} /> <br /> <br />
                </span>
            )
            break;

        case 6:
            // Datetime
            return (
                <span>
                    <label>{props.name}</label> <b>"COMPLEX OBJECT" (JSON)</b> <br /> <br />
                </span>
            )
            break;

        case 7:
            // Date
            return (
                <span>
                    <label>{props.name}</label> <input type='date' name={props.name} defaultValue={props.value} /> <br /> <br />
                </span>
            )
            break;

        case 8:
            // Time
            return (
                <span>
                    <label>{props.name}</label> <input type='time' name={props.name} defaultValue={props.value} /> <br /> <br />
                </span>
            )
            break;

        case 9:
            // URL
            return (
                <span>
                    <label>{props.name}</label> <input type='text' name={props.name} defaultValue={props.value} /> <b>&lArr; MUST BE VALID URL</b> <br /> <br />
                </span>
            )
            break;

        case 10:
            // List
            return (
                <span>
                    <label>{props.name}</label> <b>DROPDOWN LIST FIELD</b> <br /> <br />
                </span>
            )
            break;

        case 11:
            // MultiSelect List
            return (
                <span>
                    <label>{props.name}</label> <b>DROPDOWN LIST FIELD - MULTISELECT</b> <br /> <br />
                </span>
            )
            break;
    }
}