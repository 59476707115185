import React from "react";

export default function MessageBox (props) {
    return (
        <div className='mbox-background'>
            <div className='messagebox'>
                {props.children}
            </div>
        </div>
    )
}