import React from "react";
import MessageBox from "./MessageBox";

export default function NotFound() {
    return (
        <MessageBox>
            <h1>Error - 404</h1>
            Page not found<br />
            <a href='/'>Go home</a>
        </MessageBox>
    )
}