const makeRequest = async (url, body = null, headers = '', type = "GET") => {
    const req = await fetch(url, {
        method: type,
        cache: 'no-cache',
        headers: headers,
        body: body
    })

    return {
        'headers': [...req.headers],
        'data': await req.json()
    }
}

export { makeRequest }